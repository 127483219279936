import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import {
    BrowserView,
    MobileOnlyView,
    TabletView
} from "react-device-detect";
import SEO from "../../components/SEO"

import styles from "../../css/page.module.css"

const transition = { duration: 1.2, delay:0.8, ease: [0.43, 0.13, 0.23, 0.96] }


const clipVariants = {
    initial: {  
                clipPath: "inset(0px 0px 0px 0%)" ,
            },
    enter: {
            
            clipPath: "inset(0px 50% 0px 0px)",
            transition: {
                duration: 1,
                type: "tween",
                ease: "easeOut",
                delay: 1,
            },
    },
    exit: {
        clipPath: "inset(0px 100% 0px 0px)",
        transition: {
            duration: 0.2,
            type: "tween",
            ease: "easeIn",
           
        },
    }
}

const clipMobileVariants = {
    initial: {
        delay: 1,
        clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 0% 0%)" 
    },
    enter: {
        clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)", transition: { duration: 0.8, ease: "easeOut", delay: 1}
    },
    exit: {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        transition: { duration: 0.3, delay: 0.3, ease: "easeOut" }
    }
}

const textVariants = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1, transition: {
            duration: 0.8,
            type: "tween",
            ease: "easeOut",
            delay: 2,
        }, },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
            type: "tween",
            ease: "easeOut",

        },
    }
}

const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        }
    }

    return <Img {...normalizedProps} />
}


const Page = ({ pageContext }) => {

    const { page: { title, content, featuredImage, acfPage: { image, portfolioDescription, siteUrl } },} = pageContext
    // console.log(pageContext.index)
    const imageData = image.imageFile.childImageSharp.fluid
    const featuredImageData = featuredImage.imageFile.childImageSharp.fluid
    return (
        <motion.div
            className= {styles.container}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={{
                exit: { transition: { delayChildren: 0} },
                enter: { transition: { delayChildren: 0.5 } }
            }}
        >
            
            <SEO title={title} />

            {/* 
            <motion.div 
                className={styles.featured} 
            >
                <FluidImage image={image} style={{ marginBottom: "15px" }} />
            </motion.div> */}

            {/* <motion.div className={styles.featured} variants={featuredVariants}>
                <FluidImage image={featuredImageData} style={{ marginBottom: "15px" }} />
            </motion.div> */}

            <MobileOnlyView>
                <div className={styles.mobileContainer}>
                    <motion.div className={styles.portPic} variants={clipMobileVariants}>
                        {/* <motion.img srcset={featuredImageData.srcSet} variants={clipVariants} /> */}
                        <Img fluid={imageData} />
                        {/* <NonStretchedImage fluid={imageData} /> */}
                    </motion.div>

                    <div className={styles.title}>
                        <motion.h2 variants={textVariants}>
                            {title}
                        </motion.h2>

                        {/* <motion.h3 variants={textVariants}>
                            skill summary
                        </motion.h3> */}
                        <motion.p variants={textVariants}>
                            {portfolioDescription}
                        </motion.p>

                        <motion.a href={siteUrl} variants={textVariants}>
                            {siteUrl}
                        </motion.a>
                    </div>
                </div>
            </MobileOnlyView>

            <BrowserView>
                <div className={styles.contentContainer}>
                    <motion.div variants={clipVariants}>
                        {/* <motion.img srcset={featuredImageData.srcSet} variants={clipVariants} /> */}
                        <NonStretchedImage fluid={featuredImageData} />
                    </motion.div>

                    <div className={styles.title}>
                        <motion.h1 variants={textVariants}>
                            {title}
                        </motion.h1>

                        {/* <motion.h3 variants={textVariants}>
                            Overview
                        </motion.h3> */}

                        <motion.p variants={textVariants}>
                            {portfolioDescription}
                        </motion.p>

                        <motion.a href={siteUrl} variants={textVariants}>
                            {siteUrl ? `${siteUrl}` : ``}
                        </motion.a>
                    </div>
                </div>
            </BrowserView>

            <TabletView>
                <div className={styles.contentContainer}>
                    <motion.div variants={clipVariants}>
                        {/* <motion.img srcset={featuredImageData.srcSet} variants={clipVariants} /> */}
                        <NonStretchedImage fluid={featuredImageData} />
                    </motion.div>

                    <div className={styles.title}>
                        <motion.h1 variants={textVariants}>
                            {title}
                        </motion.h1>

                        <motion.h3 variants={textVariants}>
                            skill summary
                        </motion.h3>

                        <motion.p variants={textVariants}>
                            {portfolioDescription}
                        </motion.p>

                        <motion.a href={siteUrl} variants={textVariants}>
                            {siteUrl ? `${siteUrl}` : ``}
                        </motion.a>
                    </div>
                </div>
            </TabletView>
            

            <motion.div 
                className={styles.mainContent} 
                variants={textVariants} 
            >
                <div dangerouslySetInnerHTML={{ __html: content }} /> 
            </motion.div>

            
            {/* <motion.div className={styles.backToHome} variants={textVariants}  >
                <Link to="/"> ⟵ Back to home</Link>
            </motion.div> */}

        </motion.div>

    )
}

export default Page

